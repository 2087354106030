import React, { useState } from "react";

import styles from "./css/heroImage.module.scss";

const HeroImage = ({ hero }) => {
  const {
    featuredImage: {
      file: { url },
      resize,
    },
    title,
    id,
  } = hero;

  const [loading, setLoading] = useState(true);

  return (
    <div style={{ height: 200 }}>
      {loading && (
        <img
          alt={title}
          key={id}
          src={url}
          style={{ display: "none" }}
          onLoad={() => setLoading(false)}
        />
      )}
      {!loading && (
        <div
          className={styles.hero}
          style={{
            position: "absolute",
            width: "100%",
            backgroundImage: `url("${url}")`,
          }}
        />
      )}
      <div
        className={styles.hero}
        style={{
          width: "100%",
          backgroundSize: "cover",
          backgroundImage: `url("${resize.src}")`,
        }}
      />
    </div>
  );
};

export default HeroImage;
