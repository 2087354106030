import { RightOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Space, Typography } from "antd";
import Carousel from "antd-mobile/lib/carousel";
import { Link, navigate } from "gatsby";
import { find, get, groupBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import ProductCard from "../../components/ProductCard";
import { getCategorySlug } from "../../components/URLSync";
import { CAROUSEL_INTERVAL } from "../../utils/constants";
import { useListProducts } from "../../utils/graphql";
import HeroImage from "./HeroImage";

const { Title, Text } = Typography;

const CategoryCard = ({ category, hierarchicalCategory = "" }) => {
  const to = `/app/search/${getCategorySlug(hierarchicalCategory)}`;
  const title = category.title;
  const imgUrl =
    get(category, "img.file.url") ||
    get(category, "product[0].images[0].file.url");

  return (
    <Link to={to}>
      <div
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          padding: 5,
          minHeight: 150,
        }}
      >
        <div
          style={{
            background: `url("${imgUrl}")`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: 80,
            width: 80,
            border: "1.5px solid #f2bb55",
            borderRadius: 80,
            display: `inline-block`,
            marginBottom: 10,
          }}
        />
        <Text style={{ width: "100%", textAlign: "center", lineHeight: "1.2" }}>
          {title}
        </Text>
      </div>
    </Link>
  );
};

const Landing = ({ categories, heroImages }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { products } = useListProducts();

  const renderHero = () => {
    return (
      <Carousel
        cellSpacing={10}
        slideWidth={1}
        autoplay
        autoplayInterval={CAROUSEL_INTERVAL}
        infinite
      >
        {heroImages.map((heroImage) => (
          <HeroImage key={heroImage.id} hero={heroImage} />
        ))}
      </Carousel>
    );
  };

  const renderAllProducts = () => {
    const productsGroupedById = groupBy(products, `contentful_id`);
    const productsMappedById = Object.values(productsGroupedById);

    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="space-between">
          <Title style={{ textAlign: "left" }} level={3}>
            {t("landing.products")}
          </Title>
          <Button type="link" onClick={() => navigate("/app/search")}>
            {t("landing.seeMore")}: <RightOutlined style={{ fontSize: 11 }} />
          </Button>
        </Row>
        <div style={{ overflowX: "auto" }}>
          <div style={{ display: "flex" }}>
            {productsMappedById
              .filter((p, i) => i < 8)
              .map((products) => {
                const product = find(
                  products,
                  (product) => product.node_locale === language
                );
                const fallback = find(
                  products,
                  (product) => product.node_locale === "en"
                );

                return (
                  <ProductCard
                    product={product}
                    fallback={fallback}
                    key={product?.contentful_id}
                  />
                );
              })}
          </div>
        </div>
      </Space>
    );
  };

  const renderCategories = () => {
    return (
      <>
        <Row justify="space-between">
          <Title style={{ textAlign: "left" }} level={3}>
            {t("landing.categories")}
          </Title>
        </Row>
        <Col span={24} style={{ overflowX: "auto" }}>
          <Space>
            {categories.map((category) => {
              return (
                <CategoryCard
                  key={category.id}
                  category={category}
                  hierarchicalCategory={category.hierarchicalCategory}
                />
              );
            })}
            <CategoryCard category={{ slug: "", title: "Browse All" }} />
          </Space>
        </Col>
      </>
    );
  };

  return (
    <Row justify="center">
      <Col>
        {renderHero()}
        <Space
          size="large"
          direction="vertical"
          style={{ width: "100%", marginTop: 30 }}
        >
          {renderCategories()}
          <Divider />
          {renderAllProducts()}
        </Space>
      </Col>
    </Row>
  );
};

export default Landing;
