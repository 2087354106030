import { graphql } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ErrorBoundary from "../components/ErrorBoundary";
import LandingDesktop from "../routes/landing/LandingDesktop";
import LandingMobile from "../routes/landing/LandingMobile";

const Landing = ({ data }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 1224 });
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const allCategories = data.allContentfulCategory.nodes;
  const heroImages = data.allContentfulHeroImage.nodes;
  const categories = allCategories
    .filter((category) => {
      if (category.hierarchyLevel !== 1) {
        return false;
      }
      if (!category.product || !category.product.length) {
        return false;
      }
      if (category.node_locale !== language) {
        return false;
      }
      return true;
    })
    .map((node) => {
      const parent = allCategories.find(
        (parentCategory) =>
          parentCategory.hierarchyLevel === node.hierarchyLevel - 1 &&
          parentCategory.subcategories?.some(
            (subCategory) => subCategory.id === node.id
          )
      );

      return {
        ...node,
        hierarchicalCategory: parent
          ? `${parent.title} > ${node.title}`
          : node.title,
      };
    });

  return (
    <ErrorBoundary>
      {isDesktop && (
        <LandingDesktop heroImages={heroImages} categories={categories} />
      )}
      {isMobile && (
        <LandingMobile heroImages={heroImages} categories={categories} />
      )}
    </ErrorBoundary>
  );
};

export default Landing;

export const query = graphql`
  query {
    allContentfulHeroImage(filter: { node_locale: { eq: "en" } }) {
      nodes {
        id
        title
        featuredImage {
          file {
            url
          }
          resize(quality: 0, width: 1, toFormat: JPG) {
            src
          }
        }
      }
    }
    allContentfulCategory(
      filter: { hierarchyLevel: { lte: 1 } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        node_locale
        id
        slug
        hierarchyLevel
        title
        img {
          file {
            url
          }
        }
        product {
          images {
            file {
              url
            }
          }
        }
        subcategories {
          id
        }
      }
    }
  }
`;
