import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Typography } from "antd";
import { Link } from "gatsby";
import { get } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";

import SiteMetadata from "../../components/SiteMetadata";
import { getCategorySlug } from "../../components/URLSync";
import { CAROUSEL_INTERVAL } from "../../utils/constants";
import styles from "./css/landing.module.scss";

const { Title } = Typography;

const CategoryCard = ({ category, hierarchicalCategory = "" }) => {
  const to = `/app/search/${getCategorySlug(hierarchicalCategory)}`;
  const imgUrl =
    get(category, "img.file.url") ||
    get(category, "product[0].images[0].file.url");
  const title = category.title;

  return (
    <Link className={styles.card} to={to}>
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url("${imgUrl}")` }}
      />
      <div className={styles.info}>{title}</div>
    </Link>
  );
};

const Landing = ({ categories, heroImages }) => {
  const { t } = useTranslation();

  return (
    <>
      <SiteMetadata />
      <section className={styles.section}>
        <Carousel
          autoPlay={true}
          interval={CAROUSEL_INTERVAL}
          showArrows={false}
          infiniteLoop={true}
          dynamicHeight={false}
          showThumbs={false}
          showStatus={false}
        >
          {heroImages.map((heroImage) => {
            return (
              <div
                key={heroImage.id}
                className={styles.hero}
                style={{
                  backgroundImage: `url("${heroImage.featuredImage.file.url}")`,
                }}
              />
            );
          })}
        </Carousel>
      </section>
      <section className={styles.section}>
        <Title level={3}>{t("nav.shopWithUs")}</Title>
        <div className={styles.categories}>
          {categories.map((category) => {
            return (
              <CategoryCard
                key={category.id}
                category={category}
                hierarchicalCategory={category.hierarchicalCategory}
              />
            );
          })}
          <CategoryCard category={{ slug: "", title: "Browse All" }} />
        </div>
      </section>
    </>
  );
};

export default Landing;
